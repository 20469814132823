import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import LoaderComponent from '../../../components/Loader/loader.component';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { IUser, UserSteps } from '../../../types/userInterface';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';

interface RetroalimentacionScreenProps {
  user: Partial<IUser>;
  token: string;
}

const RetroalimentacionScreen: React.FC<RetroalimentacionScreenProps> = () => {
  const [user, setUser] = useState<Partial<IUser>>({});
  const [loading, setLoading] = useState(true);

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Llamada telefónica',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { olaUser } = currentUser;
          setUser(olaUser);
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="container module-top">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="text-64 mb-3">Hola, {user.username}</h1>
                <p className="text-32 mb-0">
                  <strong>No. {user.userId}</strong>
                </p>
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12">
                <h2 className="h1">Este es el camino en tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <div className="col-12">
                <h3 className="h2 text-400">
                  Tu retroalimentación es muy importante para hacer mejor
                  nuestro trabajo
                </h3>
                <div className="alert alert--simple-check col-md-8 col-12">
                  <span className="icon material-icons-outlined">
                    check_circle
                  </span>
                  <span className="text-20">
                    <strong>
                      Queremos conocer cómo te has sentido en los siguientes 15
                      días posteriores a tu procedimiento.
                    </strong>
                  </span>
                </div>
                <article>
                  <ul className="text-20">
                    <li className="mb-3">
                      Para nosotras es fundamental que las mujeres compartan sus
                      opiniones sobre la experiencia de acceso a una
                      interrupción voluntaria del embarazo en los hospitales de
                      Chile.
                    </li>
                    <li className="mb-3">
                      Tu opinión también nos ayuda a mejorar nuestro trabajo de
                      acompañamiento integral.
                    </li>
                  </ul>
                </article>
              </div>
              <div className="col-lg-5 col-12">
                <div className="form-row module-top40 form--last">
                  <Link
                    to="/bitacora-post-procedimiento"
                    className="btn btn--type1"
                    onClick={(e) => {
                      if (user.step && user.step <= UserSteps.BITACORA) {
                        e.preventDefault();
                        Swal.fire({
                          title: 'Aún no es posible continuar',
                          text: 'Tan pronto se confirme tu procedimiento, podrás continuar.',
                          icon: 'info',
                          confirmButtonText: 'Aceptar',
                          cancelButtonText: 'De acuerdo',
                        });
                      }
                    }}
                  >
                    Seguimiento posterior
                  </Link>
                </div>
                <div className="form-row form--last">
                  <button type="button" className="btn btn--type2">
                    Ya no necesito este acompañamiento
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default RetroalimentacionScreen;
