import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import LoaderComponent from '../../../components/Loader/loader.component';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { IQuestion, strapiDoc } from '../../../types/strapiDocs';
import FooterShared from '../../../shared/footer.shared';
import HeaderShared from '../../../shared/header.shared';
import { fetchStrapiSingle } from '../../../api/strapi';
import { updateUser } from '../../../api/usersApi';
import {
  IPostDeliveryStatus,
  IUser,
  UserSteps,
} from '../../../types/userInterface';

const BitacoraPostProcedimientoScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState<string>('');
  const [content, setContent] = useState<Partial<strapiDoc>>({});
  const [formularioValido, setFormularioValido] = useState(true);
  const [checkboxAnswers, setCheckboxAnswers] = useState<
    {
      documentId: string;
      question: string;
      value: string[];
    }[]
  >([]);

  const navigate = useNavigate();

  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'En proceso',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Llamada telefónica',
      description: 'Pendiente',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Seguimos en contacto',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const verifyFields = (postDeliveryStatus: Partial<IPostDeliveryStatus>[]) => {
    const questions = postDeliveryStatus || [];
    const contentQuestions = content.attributes?.questions || [];

    const requiredQuestions = contentQuestions.filter(
      (question) => question.requiredField
    );
    const answeredQuestions = requiredQuestions.filter((question) =>
      questions.some((q) => q.question?.documentId === `${question.id}`)
    );

    const hasContent = requiredQuestions.length && answeredQuestions.length;
    if (!hasContent) {
      setFormularioValido(false);
      return false;
    }

    if (answeredQuestions.length !== requiredQuestions.length) {
      setFormularioValido(false);
    } else {
      setFormularioValido(true);
    }
  };

  const fetchSection = async () => {
    try {
      setLoading(true);
      const response = await fetchStrapiSingle(
        '/seguimiento-post-procedimiento',
        {
          populate: '*',
        }
      );
      if (response.data) {
        setContent(response.data);
      }
    } catch (error) {
      console.error('Error al obtener la sección:', error);
      setError('Error al obtener la sección');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (
    question: IQuestion,
    value: string,
    checked: boolean
  ) => {
    const checkboxAnswer = [...checkboxAnswers].find(
      (question) => question.documentId === question.documentId
    );
    const answers = checkboxAnswer?.value || [];

    if (checked) {
      answers.push(value);
    } else {
      const index = answers.indexOf(value);
      if (index !== -1) {
        answers.splice(index, 1);
      }
    }

    if (checkboxAnswer) {
      checkboxAnswer.value = answers;
      setCheckboxAnswers(checkboxAnswers);
    } else {
      const newCheckboxAnswer = {
        documentId: `${question.id}`,
        question: question.Nombre,
        value: answers,
      };
      (checkboxAnswers || []).push(newCheckboxAnswer);
      setCheckboxAnswers(checkboxAnswers);
    }

    setUserQuestion(question, answers.join(', '));
  };

  const setUserQuestion = (question: IQuestion, value: string) => {
    const userQuestions = user.postDeliveryStatus || [];
    const existingQuestion = userQuestions.find(
      (q) => q.question.documentId === `${question.id}`
    );
    if (existingQuestion) {
      existingQuestion.question.documentId = `${question.id}`;
      existingQuestion.question.value = question.Nombre;
      existingQuestion.answer = value;
    } else {
      userQuestions.push({
        question: {
          documentId: `${question.id}`,
          value: question.Nombre,
        },
        answer: value,
      });
    }

    console.log('question', { question, value, userQuestions });
    setUser({
      ...user,
      postDeliveryStatus: userQuestions,
    });
  };

  const renderQuestion = (question: IQuestion) => {
    const { id, showMoreDesc, type, requiredField } = question;
    const options = question.options || [];
    const option = options.find((option) => option.type === 'list');
    const optionValues =
      option?.children?.map((child) => child.children[0]?.text || '') || [];
    const currentEvaluation = user.postDeliveryStatus || [];
    const currentValue =
      currentEvaluation?.find(({ question }) => question.documentId === `${id}`)
        ?.answer || '';

    if (type === 'radio') {
      return (
        <div
          className={`${
            optionValues.length > 2 ? 'form-block' : 'form-check-multi '
          }`}
        >
          {optionValues.map((option, index) => (
            <div
              key={`option-${index}`}
              className={`form-check ${optionValues.length > 2 ? 'mb-3' : ''}`}
            >
              <input
                className={`form-check-input ${
                  requiredField ? 'requiredField' : ''
                }`}
                type="radio"
                name={`question-${id}`}
                id={`question-${id}-${index}`}
                value={option}
                checked={currentValue === option}
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setUserQuestion(question, selectedOption);
                }}
              />
              <label
                className="form-check-label"
                htmlFor={`question-${id}-${index}`}
              >
                {option}
              </label>
            </div>
          ))}
        </div>
      );
    }

    if (type === 'text') {
      return (
        <input
          type="text"
          className={`form-control ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          placeholder={showMoreDesc}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setUserQuestion(question, value);
          }}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <textarea
          className={`form-control ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          placeholder={showMoreDesc}
          rows={3}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setUserQuestion(question, value);
          }}
        />
      );
    }

    if (type === 'select') {
      return (
        <select
          className={`form-select ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setUserQuestion(question, value);
          }}
        >
          <option value="">Selecciona una opción</option>
          {optionValues.map((option, index) => (
            <option key={`option-${index}`} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    if (type === 'checkbox') {
      return (
        <div className="row-check-2 row">
          {optionValues.map((option, index) => (
            <div className="row-check-2__col col-12" key={index}>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    requiredField ? 'requiredField' : ''
                  }`}
                  type="checkbox"
                  id={`question-${id}`}
                  value={option}
                  onChange={(e) => {
                    const selectedOption = e.target.value;
                    const checked = e.target.checked;
                    handleCheckboxChange(question, selectedOption, checked);
                  }}
                />
                <label className="form-check-label" htmlFor={`question-${id}`}>
                  {option}
                </label>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return <></>;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await updateUser(
        user._id || '',
        {
          postDeliveryStatus: user.postDeliveryStatus || [],
          step: UserSteps.POST_DELIVERY_STATUS,
        },
        token
      );
      if (response) {
        navigate('/seguimiento'); // evaluaciones
      }
    } catch (error) {
      setError('Error al enviar la evaluación');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { olaUser, jwtToken } = currentUser;
          setUser(olaUser);
          setToken(jwtToken);
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    fetchSection();
  }, []);

  useEffect(() => {
    verifyFields(user.postDeliveryStatus || []);
  }, [user]);

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <div className="col-12 mb-4">
                <a
                  href="#"
                  className="btn--icon btn--simple btn--simple--small"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <i className="icon fa-solid fa-arrow-left"></i> Regresar
                </a>
              </div>
              <div className="row">
                <div className="col-md-5 col-12">
                  <h2 className="text-green900 text-40 mb-3">
                    {content.attributes?.header || ''}
                  </h2>
                  <article className="text-20">
                    <p>{content.attributes?.subheader}</p>
                    <p className="text-green350">
                      <strong>{content.attributes?.alert}</strong>
                    </p>
                  </article>
                  <div className="form-block">
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        {content.attributes?.questions?.map(
                          (pregunta, index) => {
                            const { Nombre, tooltip, requiredField } = pregunta;
                            return (
                              <div className="form-row" key={index}>
                                <label className="form-label label--icon">
                                  {Nombre}{' '}
                                  {requiredField && (
                                    <small className="required">*</small>
                                  )}
                                  {tooltip && (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip>{tooltip}</Tooltip>}
                                    >
                                      <span className="material-icons-outlined icon">
                                        help_outline
                                      </span>
                                    </OverlayTrigger>
                                  )}
                                </label>
                                {pregunta.type === 'checkbox' && (
                                  <p className="text-gray600">
                                    Selecciona las opciones que correspondan
                                  </p>
                                )}
                                {renderQuestion(pregunta)}
                              </div>
                            );
                          }
                        )}
                        <div className="form-row  form--last">
                          <button
                            type="submit"
                            className="btn btn--type1"
                            disabled={!formularioValido}
                          >
                            Siguiente
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default BitacoraPostProcedimientoScreen;
