import React, { useEffect, useState } from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';
import cards from '../../../utils/cardsGestion2.json';
import StopProcessComponent from '../../../components/Modals/StopProcessModal/stopProcessModal.component';
import StopConfirmComponent from '../../../components/Modals/StopConfirmModal/stopConfirmModal.component';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { IUser, UserSteps } from '../../../types/userInterface';
import { strapiDoc } from '../../../types/strapiDocs';
import { updateUser } from '../../../api/usersApi';
import { fetchStrapiDocs } from '../../../api/strapi';
import HospitalCardMap from '../../../components/Hospitals/hospitalMap.component';
import LoaderComponent from '../../../components/Loader/loader.component';

interface Props {
  // Define your component props here
}

const GestionAprobacionScreen: React.FC<Props> = () => {
  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Llamada telefónica',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState<string>('');
  const [hospitales, setHospitales] = useState<strapiDoc[]>([]);
  const [loading, setLoading] = useState(true);

  const { state } = useLocation();
  const navigate = useNavigate();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchHospitales = async (user: Partial<IUser>) => {
    try {
      setLoading(true);
      const hospitalsSelected = user.hospitalsSelected || [];
      if (!hospitalsSelected.length) {
        return;
      }
      const ids = hospitalsSelected.map(({ hospitalId }) => hospitalId);
      const response = await fetchStrapiDocs('/hospitales', {
        populate: '*',
        sort: ['id:asc'],
        filters: {
          id: {
            $in: ids,
          },
        },
      });

      setHospitales(response.data);
    } catch (error) {
      console.error('Error fetching hospitales:', error);
      setError('Error al obtener los hospitales');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    try {
      setLoading(true);
      const response = await updateUser(
        user._id || '',
        {
          hospitalsSelected: user.hospitalsSelected || [],
          logs: user.logs || [],
          step: UserSteps.GESTION,
        },
        token
      );
      if (response) {
        return navigate('/gestora');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setModalShow(false);
    setModalShowConfirm(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state && state.user && state.token) {
      setUser(state.user as Partial<IUser>);
      setToken(state.token as string);
      fetchHospitales(state.user as Partial<IUser>);
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <StopProcessComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <StopConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <HeaderShared />
      <section className="module-top container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <PrivacyComponent />
          <div className="col-12 col-reset">
            <div className="module-bottom40 ">
              <ProcessStatusComponent steps={steps} currentStepIndex={0} />
            </div>
            <div className="module-bottom40">
              <div className="text-center">
                <h1 className="text-52">Gestiona tu procedimiento</h1>
                <p className="text-24">Confirmar mi selección</p>
              </div>
            </div>
            <section className="hospital-wrapp">
              <div className="row">
                {hospitales.map((hospital, index) => {
                  const {
                    nombre,
                    servicio,
                    direccion,
                    telefono,
                    review,
                    latitud,
                    longitud,
                  } = hospital.attributes;
                  return (
                    <HospitalCardMap
                      key={index}
                      id={hospital.id}
                      name={nombre}
                      service={servicio}
                      address={direccion}
                      phone={[telefono || '']}
                      review={review}
                      lat={latitud}
                      long={longitud}
                    />
                  );
                })}
              </div>
            </section>
          </div>
        </div>
      </section>
      <section className="module">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto col-12 d-flex justify-content-center mb-4">
              <a
                href="#"
                className="btn btn--type1 btn--100"
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateUser();
                }}
              >
                Confirmar mi selección y continuar en el chat con mi gestora
              </a>
            </div>
            <div className="col-md-8 mx-auto col-12 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn--type2 btn--100"
                onClick={() => setModalShow(true)}
              >
                Ya no necesito este acompañamiento
              </button>
            </div>
          </div>
        </div>
      </section>
      <CardWrapperComponent
        color="base"
        title="Descarga aquí los pasos a seguir para un aborto por causales"
        titleSecond="¿Qué información necesitas antes de ir a un hospital a solicitar un aborto?"
        cards={cards}
      />
      <FooterShared />
    </>
  );
};

export default GestionAprobacionScreen;
