import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import { IQuestion } from '../../../types/strapiDocs';
import { IUser } from '../../../types/userInterface';
import Swal from 'sweetalert2';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import LoaderComponent from '../../Loader/loader.component';
import { updateUser } from '../../../api/usersApi';

interface Props {
  questions: IQuestion[];
  confianzaList: {
    id: number;
    content: string;
  }[];
}

interface Answer {
  id: number;
  question: string;
  answer: string;
  detail?: string | undefined;
  confianzaList?: string[] | undefined;
}

const SexualidadEmocionesComponent = ({ questions, confianzaList }: Props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [user, setUser] = useState<Partial<IUser>>({});
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();

    camposRequeridos.forEach((campo) => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
      } else {
        // Si el campo es el textarea y está oculto, lo ignoramos
        if (campo.type === 'textarea' && campo.hidden) {
          return;
        }
        todosCompletos = todosCompletos && campo.value !== '';
      }
    });

    gruposRadio.forEach((nombre) => {
      const seleccionado =
        document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;
    });

    setFormularioValido(todosCompletos);
  };

  const handleDelete = () => {
    setModalShow(false);
    setModalShowConfirm(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const currentUser = {
        ...user,
      };
      const userId = currentUser._id || '';

      currentUser.step = 9;
      const sexualidadYEmociones = currentUser.sexualidadYEmociones || {};
      sexualidadYEmociones.questions = answers;
      currentUser.sexualidadYEmociones = sexualidadYEmociones;

      await updateUser(userId, currentUser, token);
      navigate('/violentometro');
    } catch (error) {
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const isChecked = (questionId: number, value: string) => {
    const isCheck =
      answers.some((a) => a.id === questionId && a.answer === value) || false;
    return isCheck;
  };

  const renderQuestions = () => {
    return questions.map((question, index) => (
      <React.Fragment key={index}>
        <div className="form-row">
          <label className="form-label label--icon">
            <Markdown
              allowedElements={['strong']}
              unwrapDisallowed={true}
              components={{
                strong: (props: React.DOMAttributes<HTMLParagraphElement>) => (
                  <strong className="text-purple500 text-underline">
                    {props.children || ''}
                  </strong>
                ),
              }}
            >
              {question.Nombre}
            </Markdown>{' '}
            {question.requiredField && <small className="required">*</small>}{' '}
            {question.tooltip && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{question.tooltip}</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            )}
          </label>
          <div className="form-check-multi">
            {renderQuestionOptions(question)}
          </div>
        </div>
        {renderQuestionDetail(question)}
      </React.Fragment>
    ));
  };

  const renderQuestionOptions = (question: IQuestion) => {
    const options = [
      {
        value: 'si',
        label: 'Sí',
        name: question.id,
      },
      {
        value: 'no',
        label: 'No',
        name: question.id,
      },
      {
        value: 'no-estoy-segura',
        label: 'No estoy segura',
        name: question.id,
      },
    ];
    if (question.showList) {
      return null;
    }
    if (question.showOtherOption) {
      options.push({
        value: 'prefiero-hacerlo-sola',
        label: 'Prefiero hacerlo sola',
        name: question.id,
      });
    }
    return options.map((option, index) => (
      <div className="form-check" key={index}>
        <input
          className={`form-check-input ${
            question.requiredField ? 'requiredField' : ''
          }`}
          type="radio"
          name={`${option.name}`}
          id={`${option.value}-${question.id}`}
          value={option.value}
          checked={isChecked(question.id, option.value)}
          onChange={(e) => {
            const currentAnswers = [...answers];
            const index = currentAnswers.findIndex((a) => a.id === question.id);
            if (index > -1) {
              currentAnswers[index].answer = option.value;
            } else {
              currentAnswers.push({
                id: question.id,
                question: question.Nombre,
                answer: e.target.value,
                detail: '',
              });
            }
            setAnswers(currentAnswers);
          }}
        />
        <label
          className="form-check-label"
          htmlFor={`${option.value}-${question.id}`}
        >
          {option.label}
        </label>
      </div>
    ));
  };

  const renderQuestionDetail = (question: IQuestion) => {
    const currentValue =
      answers.find((a) => a.id === question.id)?.detail || '';
    const currentConfianzaList = confianzaList || [];
    if (question.showList) {
      return (
        <div>
          <p className="text-gray600">Puedes seleccionar más de una opción</p>
          <div className="row-check-2 row">
            {currentConfianzaList.map((confianza, index) => {
              const currentAnswers = [...answers];
              let checked = false;
              const i = currentAnswers.findIndex((a) => a.id === question.id);
              if (i > -1) {
                const confianzaList = currentAnswers[i].confianzaList || [];
                checked = confianzaList.includes(confianza.content);
              }
              return (
                <div className="row-check-2__col col-6" key={index}>
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="confianzaList"
                      id={confianza.id.toString()}
                      value={confianza.content}
                      checked={checked}
                      onChange={(e) => {
                        const currentAnswers = [...answers];
                        const index = currentAnswers.findIndex(
                          (a) => a.id === question.id
                        );
                        if (index > -1) {
                          const confianzaList =
                            currentAnswers[index].confianzaList || [];
                          confianzaList.push(e.target.value);
                        } else {
                          currentAnswers.push({
                            id: question.id,
                            question: question.Nombre,
                            answer: '',
                            detail: '',
                            confianzaList: [e.target.value],
                          });
                        }
                        setAnswers(currentAnswers);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={confianza.id.toString()}
                    >
                      {confianza.content}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (!question.showMore) {
      return null;
    }
    return (
      <div className="form-row">
        <label className="form-label text-gray600">
          {question.showMoreDesc || ''}
        </label>
        <textarea
          className="form-control"
          placeholder="Cuéntanos por aquí"
          value={currentValue}
          onChange={(e) => {
            const currentAnswers = [...answers];
            const index = currentAnswers.findIndex((a) => a.id === question.id);
            if (index > -1) {
              currentAnswers[index].detail = e.target.value;
            } else {
              currentAnswers.push({
                id: question.id,
                question: question.Nombre,
                answer: '',
                detail: e.target.value,
              });
            }
            setAnswers(currentAnswers);
          }}
        ></textarea>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (!currentUser) {
          navigate('/');
        }
        setToken(currentUser?.jwtToken || '');
        setUser(currentUser?.olaUser || {});
        const sexualidadYEmociones =
          currentUser?.olaUser?.sexualidadYEmociones || {};
        const preguntas = sexualidadYEmociones.questions || [];
        setAnswers(preguntas);
        verifyFields();
      } catch (error) {
        setError(`${error}`);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    verifyFields();
  }, [answers]);

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
        setLoading={setLoading}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          {renderQuestions()}
          <div className="form-row module-top40">
            <a href="tel:569982729131" className="btn btn--type2 btn--icon">
              <i className="icon icon--phone-call"></i>
              Información de aborto legal en Chile 569 982 729 131
            </a>
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SexualidadEmocionesComponent;
