import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Markdown from 'react-markdown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useNavigate } from 'react-router-dom';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import {
  IUser,
  IUserHospitalSelected,
  StrapiDoc,
  UserSteps,
} from '../../../types/userInterface';
import { fetchStrapiDocs, fetchStrapiSingle } from '../../../api/strapi';
import { IQuestion, strapiDoc } from '../../../types/strapiDocs';
import LoaderComponent from '../../../components/Loader/loader.component';
import { updateUser } from '../../../api/usersApi';

interface Props {
  // Define your component props here
}

interface Item {
  id: number;
  name: string;
}

const BitacoraScreen: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [hospitales, setHospitales] = useState<strapiDoc[]>([]);
  const [content, setContent] = useState<Partial<strapiDoc>>({});
  const [formularioValido, setFormularioValido] = useState(true);
  const [hospitalSelected, setHospitalSelected] = useState<
    Partial<IUserHospitalSelected>
  >({});
  const [checkboxAnswers, setCheckboxAnswers] = useState<
    {
      id: number;
      question: StrapiDoc;
      answers: string[];
    }[]
  >([]);
  const [attended, setAttended] = useState<string>('si');

  const navigate = useNavigate();

  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'En proceso',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Llamada telefónica',
      description: 'Pendiente',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Seguimos en contacto',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const verifyFields = (user: Partial<IUser>) => {
    const questions = user.bitacora || [];
    const contentQuestions = content.attributes?.questions || [];

    const requiredQuestions = contentQuestions.filter(
      (question) => question.requiredField
    );
    const answeredQuestions = requiredQuestions.filter((question) =>
      questions.some((q) => q.id === question.id)
    );

    const hasContent = requiredQuestions.length && answeredQuestions.length;
    if (!hasContent) {
      setFormularioValido(false);
      return false;
    }

    if (answeredQuestions.length !== requiredQuestions.length) {
      setFormularioValido(false);
    } else {
      setFormularioValido(true);
    }
  };

  const fetchHospitales = async () => {
    try {
      if (searchTerm === '') {
        setLoading(true);
      }
      const response = await fetchStrapiDocs('/hospitales', {
        populate: '*',
        sort: ['id:asc'],
        filters: {
          pais: 4, // Chile
          $or: [
            {
              nombre: {
                $containsi: searchTerm,
              },
            },
            {
              direccion: {
                $containsi: searchTerm,
              },
            },
            {
              servicio: {
                $containsi: searchTerm,
              },
            },
          ],
        },
      });

      setHospitales(response.data);
    } catch (error) {
      console.error('Error fetching hospitales:', error);
      setError('Error al obtener los hospitales');
    } finally {
      setLoading(false);
    }
  };

  const fetchSection = async () => {
    try {
      setLoading(true);
      const response = await fetchStrapiSingle('/bitacora-post-procedimiento', {
        populate: '*',
      });
      if (response.data) {
        setContent(response.data);
      }
    } catch (error) {
      console.error('Error al obtener la sección:', error);
      setError('Error al obtener la sección');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (
    question: IQuestion,
    value: string,
    checked: boolean
  ) => {
    const checkboxAnswer = [...checkboxAnswers].find(
      (answer) => answer.question.documentId === `${question.id}`
    );
    const answers = checkboxAnswer?.answers || [];

    if (checked) {
      answers.push(value);
    } else {
      const index = answers.indexOf(value);
      if (index !== -1) {
        answers.splice(index, 1);
      }
    }

    if (checkboxAnswer) {
      checkboxAnswer.answers = answers;
      setCheckboxAnswers(checkboxAnswers);
    } else {
      const newCheckboxAnswer = {
        id: question.id,
        question: {
          documentId: `${question.id}`,
          value: question.Nombre,
        },
        answers: answers,
      };
      (checkboxAnswers || []).push(newCheckboxAnswer);
      setCheckboxAnswers(checkboxAnswers);
    }

    setUserQuestion(question, answers.join(', '));
  };

  const setUserQuestion = (question: IQuestion, value: string) => {
    const userQuestions = user.bitacora || [];
    const existingQuestion = userQuestions.find((q) => q.id === question.id);
    if (existingQuestion) {
      existingQuestion.id = question.id;
      existingQuestion.answer = value;
      existingQuestion.question = {
        documentId: `${question.id}`,
        value: question.Nombre,
      };
    } else {
      userQuestions.push({
        id: question.id,
        answer: value,
        question: {
          documentId: `${question.id}`,
          value: question.Nombre,
        },
      });
    }

    console.log('setUserQuestion', { userQuestions });
    setUser({ ...user, bitacora: userQuestions });
  };

  const renderQuestion = (question: IQuestion) => {
    const { id, showMoreDesc, type, requiredField } = question;
    const options = question.options || [];
    const option = options.find((option) => option.type === 'list');
    const optionValues =
      option?.children?.map((child) => child.children[0]?.text || '') || [];
    const currentUser = { ...user };
    const currentValue =
      currentUser.bitacora?.find((question) => question.id === id)?.answer ||
      '';

    if (type === 'radio') {
      return (
        <div className="form-check-multi">
          {optionValues.map((option, index) => (
            <div key={`option-${index}`} className="form-check">
              <input
                className={`form-check-input ${
                  requiredField ? 'requiredField' : ''
                }`}
                type="radio"
                name={`question-${id}`}
                id={`question-${id}-${index}`}
                value={option}
                checked={currentValue === option}
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setUserQuestion(question, selectedOption);
                }}
              />
              <label
                className="form-check-label"
                htmlFor={`question-${id}-${index}`}
              >
                {option}
              </label>
            </div>
          ))}
        </div>
      );
    }

    if (type === 'text') {
      return (
        <input
          type="text"
          className={`form-control ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          placeholder={showMoreDesc}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setUserQuestion(question, value);
          }}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <textarea
          className={`form-control ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          placeholder={showMoreDesc}
          rows={3}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setUserQuestion(question, value);
          }}
        />
      );
    }

    if (type === 'select') {
      return (
        <select
          className={`form-select ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setUserQuestion(question, value);
          }}
        >
          <option value="">Selecciona una opción</option>
          {optionValues.map((option, index) => (
            <option key={`option-${index}`} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    if (type === 'checkbox') {
      return (
        <div className="row-check-2 row">
          {optionValues.map((option, index) => (
            <div className="row-check-2__col col-12" key={index}>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    requiredField ? 'requiredField' : ''
                  }`}
                  type="checkbox"
                  id={`question-${id}`}
                  value={option}
                  onChange={(e) => {
                    const selectedOption = e.target.value;
                    const checked = e.target.checked;
                    handleCheckboxChange(question, selectedOption, checked);
                  }}
                />
                <label className="form-check-label" htmlFor={`question-${id}`}>
                  {option}
                </label>
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (type === 'autocomplete') {
      return (
        <div className="form-search-complete">
          <div className="autocomplete-search">
            <ReactSearchAutocomplete
              items={hospitales.map((hospital) => ({
                id: hospital.id,
                name: hospital.attributes.nombre,
              }))}
              onSelect={(item) => {
                setUserQuestion(question, item.name);
                setHospitalSelected({
                  hospitalId: item.id,
                  isSelected: true,
                });
              }}
              onSearch={(value) => {
                if (value.length >= 2) {
                  setSearchTerm(value);
                }
              }}
              placeholder="Buscar hospital"
              className="autocomplete-search__input"
              formatResult={(item: Item) => (
                <>
                  <span style={{ display: 'block', textAlign: 'left' }}>
                    {item.name}
                  </span>
                </>
              )}
            />
          </div>
        </div>
      );
    }

    return <></>;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userId = user._id || '';
    const userUpdate: Partial<IUser> = {
      hospitalsSelected: [
        {
          hospitalId: hospitalSelected.hospitalId || 0,
          isSelected: hospitalSelected.isSelected || false,
        },
      ],
      bitacora: user.bitacora,
      step: UserSteps.BITACORA_SUCCESS,
    };
    try {
      setLoading(true);
      await updateUser(userId, userUpdate, token);
      setUser(userUpdate);
      navigate('/evaluacion-hospital');
    } catch (error) {
      setError('Error al actualizar la información');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { olaUser, jwtToken } = currentUser;
          setUser(olaUser);
          setToken(jwtToken);
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    fetchSection();
  }, []);

  useEffect(() => {
    if (searchTerm !== '') {
      fetchHospitales();
    }
  }, [searchTerm]);

  useEffect(() => {
    verifyFields(user);
    console.log('verifyFields', { user: user.bitacora });
  }, [user]);

  useEffect(() => {
    const valid = attended === 'no' || attended === 'si-pero';
    if (valid) {
      Swal.fire({
        icon: 'warning',
        title: '¡Atención!',
        text: 'Serás redireccionada al formulario correspondiente.',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (attended === 'no') {
            navigate('/evaluacion-no-asiste-hospital');
          } else {
            navigate('/evaluacion-hospital-ley-21');
          }
        }
      });
    }
  }, [attended]);

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <div className="col-12 mb-4">
                <a
                  href="#"
                  className="btn--icon btn--simple btn--simple--small"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <i className="icon fa-solid fa-arrow-left"></i> Regresar
                </a>
              </div>
              <div className="row">
                <div className="col-md-12 col-12">
                  <h2 className="text-green900 text-40 mb-3">
                    {content.attributes?.header || ''}
                  </h2>
                  <article className="text-20">
                    <Markdown>{content.attributes?.subheader || ''}</Markdown>
                    <p className="text-green350">
                      <strong>{content.attributes?.alert || ''}</strong>
                    </p>
                  </article>
                  <div className="form-block">
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        <label className="form-label label--icon">
                          ¿Pudiste acceder un aborto a través de la
                          implementación <strong>21.030</strong>?{' '}
                          <small className="required">*</small>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Implementación 21.030</Tooltip>}
                          >
                            <span className="material-icons-outlined icon">
                              help_outline
                            </span>
                          </OverlayTrigger>
                        </label>
                        <div className="form-block">
                          {[
                            { label: 'Sí', value: 'si' },
                            {
                              label:
                                'Si fuí al hospital pero no accedí a la ley 21.030',
                              value: 'si-pero',
                            },
                            { label: 'No', value: 'no' },
                          ].map((option, index) => (
                            <div className="form-check mb-3" key={index}>
                              <input
                                className="form-check-input requiredField"
                                type="radio"
                                name="attended"
                                id={`option-${index}`}
                                value={option.value}
                                onChange={(e) => {
                                  setAttended(e.target.value);
                                  setUserQuestion(
                                    {
                                      id: 0,
                                      Nombre:
                                        '¿Pudiste acceder un aborto a través de la implementación 21.030?',
                                      type: 'radio',
                                    },
                                    e.target.value
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`option-${index}`}
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      {attended === 'si' && (
                        <>
                          {content.attributes?.questions?.map(
                            (pregunta, index) => {
                              const { Nombre, tooltip, requiredField } =
                                pregunta;
                              return (
                                <div className="form-row" key={index}>
                                  <label className="form-label label--icon">
                                    {Nombre}{' '}
                                    {requiredField && (
                                      <small className="required">*</small>
                                    )}
                                    {tooltip && (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip>{tooltip}</Tooltip>}
                                      >
                                        <span className="material-icons-outlined icon">
                                          help_outline
                                        </span>
                                      </OverlayTrigger>
                                    )}
                                  </label>
                                  {pregunta.type === 'checkbox' && (
                                    <p className="text-gray600">
                                      Selecciona las opciones que correspondan
                                    </p>
                                  )}
                                  {renderQuestion(pregunta)}
                                </div>
                              );
                            }
                          )}
                          <div className="form-row  form--last">
                            <button
                              type="submit"
                              className="btn btn--type1"
                              disabled={!formularioValido}
                            >
                              Siguiente
                            </button>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default BitacoraScreen;
