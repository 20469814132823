import React from 'react';

interface Props {
  id?: number;
  name?: string;
  service?: string;
  address?: string;
  phone?: string[] | undefined;
  review?: string;
  lat?: number;
  long?: number;
  isSelected: boolean;
  handleViewDetails: (id: number) => void;
  handleSelectHospital: (id: number, selected: boolean) => void;
  allSelected: boolean;
}

const HospitalCard: React.FC<Props> = ({
  id,
  name,
  service,
  address,
  phone,
  review,
  lat,
  long,
  isSelected,
  handleViewDetails,
  handleSelectHospital,
  allSelected,
}) => {
  return (
    <div
      className="hospital-card mb-4"
      data-lat={lat}
      data-long={long}
      data-id={id}
    >
      <div className="row">
        <div className="col-lg-6 col-12">
          {name ? <h3 className="text-wine400 text-16">{name}</h3> : null}
          {service ? (
            <p>
              <strong>Servicio de Salud:</strong> {service}
            </p>
          ) : null}
          {review === 'bad' ? (
            <p className="tag tag--error ">
              <span className="material-icons-outlined">close</span> Malas
              experiencias
            </p>
          ) : review === 'good' ? (
            <p className="tag tag--success">
              <span className="material-icons-outlined">check</span> Buenas
              experiencias
            </p>
          ) : null}
          {address ? (
            <p className="text-gray600 text-icon hospital-card__text">
              <span className="material-icons-outlined">place</span> {address}
            </p>
          ) : null}
          {phone ? (
            <p className="text-icon hospital-card__text">
              <span className="material-icons-outlined">call</span>{' '}
              {phone.map((phoneNumber, index) => {
                if (index === phone.length - 1) {
                  return phoneNumber;
                } else if (index === phone.length - 2) {
                  return phoneNumber + ' y ';
                } else {
                  return phoneNumber + ', ';
                }
              })}
            </p>
          ) : null}
        </div>
        <aside className="hospital-card__end col-lg-6 col-12">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className={`btn btn--type4 ${isSelected ? 'active' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (id) {
                    const selected = isSelected;
                    handleSelectHospital(id, !selected);
                  }
                }}
                disabled={allSelected && !isSelected}
              >
                {isSelected ? 'Quitar' : 'Seleccionar'}
              </button>
            </div>
            <div className="col-12">
              <button
                type="button"
                className="btn btn--type2"
                onClick={(e) => {
                  e.preventDefault();
                  if (id) {
                    handleViewDetails(id);
                  }
                }}
              >
                Ver en mapa
              </button>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default HospitalCard;
