import api from './index';
import {
  IHospitalEvaluation,
  IHospitalEvaluationPaginate,
} from '../types/hospitalEvaluation';

interface AxiosError {
  error: string;
}

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getHospitalEvaluations = async (
  page: number,
  limit: number,
  searchText: string,
  token: string
) => {
  const response = await api.get<IHospitalEvaluationPaginate | AxiosError>(
    `/hospital-evaluation`,
    {
      params: {
        page,
        limit,
        searchText,
      },
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IHospitalEvaluationPaginate;
};

export const getHospitalEvaluation = async (
  hospitalEvaluationId: string,
  token: string
) => {
  const response = await api.get<IHospitalEvaluation | AxiosError>(
    `/hospital-evaluation/${hospitalEvaluationId}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IHospitalEvaluation;
};

export const createHospitalEvaluation = async (
  hospitalEvaluation: Partial<IHospitalEvaluation>,
  token: string
) => {
  const response = await api.post<IHospitalEvaluation | AxiosError>(
    `/hospital-evaluation`,
    hospitalEvaluation,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IHospitalEvaluation;
};

export const updateHospitalEvaluation = async (
  hospitalEvaluationId: string,
  hospitalEvaluation: Partial<IHospitalEvaluation>,
  token: string
) => {
  const response = await api.put<IHospitalEvaluation | AxiosError>(
    `/hospital-evaluation/${hospitalEvaluationId}`,
    hospitalEvaluation,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IHospitalEvaluation;
};

export const deleteHospitalEvaluation = async (
  hospitalEvaluationId: string,
  token: string
) => {
  const response = await api.delete<IHospitalEvaluation | AxiosError>(
    `/hospital-evaluation/${hospitalEvaluationId}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IHospitalEvaluation;
};

export const searchHospitalEvaluations = async (
  query: Partial<IHospitalEvaluation>,
  token: string
) => {
  const response = await api.post<IHospitalEvaluationPaginate | AxiosError>(
    '/hospital-evaluation/search',
    query,
    {
      headers: {
        ...commonHeaders,
        Authorization: `${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IHospitalEvaluationPaginate;
};
