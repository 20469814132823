import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import HospitalListComponent from '../../../components/Hospitals/hospitalList.component';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';
import cards from '../../../utils/cardsGestion.json';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { IUser } from '../../../types/userInterface';

interface Props {
  // Define your component props here
}

const GestionScreen: React.FC<Props> = () => {
  const [user, setUser] = React.useState<Partial<IUser>>({});
  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Llamada telefónica',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { olaUser } = currentUser;
          setUser(olaUser);
        }
      } catch (error) {
        setError('Error al cargar la información');
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12 text-center">
                <h1 className="text-52">Gestiona tu procedimiento</h1>
                <p className="text-24">
                  Selecciona el hospital y dos opciones más para el
                  procedimiento
                </p>
              </div>
            </div>
            <div className="module-bottom40 row">
              <HospitalListComponent />
            </div>
          </div>
        </div>
      </section>
      <CardWrapperComponent
        color="base"
        title="Descarga aquí los pasos a seguir para un aborto por causales"
        titleSecond="¿Qué información necesitas antes de ir a un hospital a solicitar un aborto?"
        cards={cards}
      />
      <section className="module-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <a href="/" className="btn btn--type1">
                Descarga la guía de hospitales
              </a>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default GestionScreen;
