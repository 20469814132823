import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import HospitalCard from './hospitalCard.component';
import { strapiDoc } from '../../types/strapiDocs';
import { fetchStrapiDocs } from '../../api/strapi';
import LoaderComponent from '../Loader/loader.component';
import { getCurrentAuthenticatedUser } from '../../utils/utils';
import { IUser, IUserLog } from '../../types/userInterface';

declare global {
  interface Window {
    google: unknown;
  }
}

interface Props {
  // Define the props for your component here
}

const HospitalListComponent: React.FC<Props> = () => {
  const PAGE_SIZE = 5;
  const [user, setUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState<string>('');
  const [googleMaps, setGoogleMaps] = useState<google.maps.Map | null>(null);
  const [hospitales, setHospitales] = useState<strapiDoc[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    pageCount: 0,
    total: 0,
  });

  const navigate = useNavigate();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleShowMore = () => {
    setPagination({
      ...pagination,
      pageSize: pagination.pageSize + PAGE_SIZE,
    });
  };

  const handleShowLess = () => {
    setPagination({
      ...pagination,
      pageSize: pagination.pageSize - PAGE_SIZE,
    });
  };

  const fetchHospitales = async (googleMaps: google.maps.Map) => {
    try {
      if (searchTerm === '') {
        setLoading(true);
      }
      const response = await fetchStrapiDocs('/hospitales', {
        populate: '*',
        sort: ['id:asc'],
        pagination: { page: pagination.page, pageSize: pagination.pageSize },
        filters: {
          $or: [
            {
              nombre: {
                $containsi: searchTerm,
              },
            },
            {
              direccion: {
                $containsi: searchTerm,
              },
            },
            {
              servicio: {
                $containsi: searchTerm,
              },
            },
          ],
        },
      });

      const {
        meta: {
          pagination: { pageCount, total },
        },
      } = response;
      setHospitales(response.data);
      setPagination({ ...pagination, pageCount, total });
      addHospitalMarkers(response.data, googleMaps);
    } catch (error) {
      console.error('Error fetching hospitales:', error);
      setError('Error al obtener los hospitales');
    } finally {
      setLoading(false);
    }
  };

  const addHospitalMarkers = (
    hospitales: strapiDoc[],
    googleMaps: google.maps.Map
  ) => {
    hospitales.forEach((hospital) => {
      const {
        attributes: { nombre, latitud, longitud },
      } = hospital;
      if (nombre && latitud && longitud) {
        new google.maps.marker.AdvancedMarkerElement({
          map: googleMaps,
          position: { lat: latitud, lng: longitud },
          title: nombre,
        });
      }
    });
  };

  const handleConfirmSelection = async (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    const hospitalsSelected = user.hospitalsSelected || [];
    if (hospitalsSelected.length === 3) {
      const userLogs = user.logs || [];
      const newLog: IUserLog = {
        logId: 0,
        logType: 'Selección de hospitales',
        note: 'Se seleccionaron 3 hospitales',
        status: 'active',
      };
      userLogs.push(newLog);
      return navigate('/gestion-aprobacion', {
        state: { user: { ...user, logs: userLogs }, token },
      });
    }
    Swal.fire({
      title: 'Falta seleccionar 3 hospitales',
      text: 'Selecciona 3 hospitales para continuar',
      icon: 'warning',
    });
  };

  useEffect(() => {
    const initMap = () => {
      const mapElement = document.getElementById('map');
      if (mapElement) {
        if (!googleMaps) {
          const map = new window.google.maps.Map(mapElement, {
            center: { lat: -35, lng: -71 },
            zoom: 6,
            mapId: 'map',
          });

          setGoogleMaps(map);
          return fetchHospitales(map);
        }

        return fetchHospitales(googleMaps);
      }
    };

    if (window.google) {
      initMap();
    }
  }, [pagination.page, pagination.pageSize, searchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { olaUser, jwtToken } = currentUser;
          setUser(olaUser);
          setToken(jwtToken);
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="hospital-wrapp">
      {loading && <LoaderComponent />}
      <div className="row">
        <div className="hospital-wrapp__search col-lg-8 col-12 mx-auto">
          <div className="ReactTags__tagInput">
            <input
              type="text"
              className="ReactTags__tagInputField"
              placeholder="Busca un hospital por comuna, palabra clave o región"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-6 col-12">
          {hospitales.map((hospital, index) => {
            const {
              nombre,
              servicio,
              direccion,
              telefono,
              review,
              latitud,
              longitud,
            } = hospital.attributes;
            return (
              <HospitalCard
                key={index}
                id={hospital.id}
                name={nombre}
                service={servicio}
                address={direccion}
                phone={[telefono || '']}
                review={review}
                lat={latitud}
                long={longitud}
                handleViewDetails={() => {
                  if (!googleMaps || !latitud || !longitud) {
                    return;
                  }
                  googleMaps.setZoom(17);
                  googleMaps.panTo({ lat: latitud, lng: longitud });
                }}
                isSelected={
                  user.hospitalsSelected?.some(
                    ({ hospitalId }) => hospitalId === hospital.id
                  ) || false
                }
                handleSelectHospital={(hospitalId, selected) => {
                  const hospitalsSelected = user.hospitalsSelected || [];
                  if (selected) {
                    hospitalsSelected.push({ hospitalId });
                  } else {
                    const index = hospitalsSelected.findIndex(
                      (hospital) => hospital.hospitalId === hospitalId
                    );
                    hospitalsSelected.splice(index, 1);
                  }
                  setUser({
                    ...user,
                    hospitalsSelected,
                  });
                }}
                allSelected={(user.hospitalsSelected || []).length === 3}
              />
            );
          })}
          <div className="d-flex justify-content-center mt-5">
            {pagination.total > pagination.pageSize && (
              <button
                type="button"
                className="btn btn--type2 btn--icon"
                onClick={handleShowMore}
              >
                <span className="icon material-icons-outlined">add</span>{' '}
                Mostrar más resultados
              </button>
            )}
            {pagination.pageSize > PAGE_SIZE && (
              <button
                type="button"
                className="btn btn--type2 btn--icon"
                onClick={handleShowLess}
              >
                <span className="icon material-icons-outlined">remove</span>{' '}
                Mostrar menos resultados
              </button>
            )}
          </div>
          <div className="d-flex justify-content-center mt-5">
            <a
              href="#"
              className="btn btn--type1"
              onClick={handleConfirmSelection}
            >
              Confirmar selección
            </a>
          </div>
        </div>
        <aside className={`map-side col-lg-6 col-12 active`}>
          <div id="map" style={{ height: '400px' }}></div>
        </aside>
      </div>
    </section>
  );
};

export default HospitalListComponent;
