import React, { useEffect, useState } from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import Step1Component from '../../../components/Process/Step1/step1.component';
import { useNavigate } from 'react-router-dom';
import { IUser, UserStatus } from '../../../types/userInterface';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import LoaderComponent from '../../../components/Loader/loader.component';
import { useAlert } from '../../../hooks/useAlerts';

interface Props {
  // Define your component props here
}

const ProcessScreen: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [user, setUser] = useState<Partial<IUser>>({});
  const { setError } = useAlert(); // Usa el hook para las alertas

  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
    {
      title: 'Llamada telefónica',
      description: 'Pendiente',
      status: 'status',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: 'status',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Seguimos en contacto',
      description: 'En proceso',
      status: 'status',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
  ];

  useEffect(() => {
    console.log({ token });
    const fetchSession = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (!currentUser) {
          navigate('/');
        }
        setToken(currentUser?.jwtToken || '');
        setUser(currentUser?.olaUser || {});
      } catch (error) {
        setError(`${error}`);
      } finally {
        setLoading(false);
      }
    };
    setTimeout(() => {
      fetchSession();
    }, 500);
  }, []);
  return user.status !== UserStatus.ACTIVE ? null : (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="mb-3 text-64">
                  Hola, {`${user.username || ''}`}
                </h1>
                <p className="mb-0 text-32">
                  <strong>No. {`${user.userId || ''}`}</strong>
                </p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h2 className="h1">Tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={1} />
              </div>
              <Step1Component />
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ProcessScreen;
