import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import Step2Component from '../../../components/Process/Step2/step2.component';
import LoaderComponent from '../../../components/Loader/loader.component';
import SimpleChatboxComponent from '../../../components/Process/Chatbox/simpleChatbox.component';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { IUser } from '../../../types/userInterface';
import { IAdmin } from '../../../types/adminInterface';

interface Props {
  // Define your component props here
}

const LlamadaScreen: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Partial<IUser>>({});

  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'En proceso',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Llamada telefónica',
      description: 'Pendiente',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { olaUser } = currentUser;
          setUser(olaUser);
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="text-64 mb-3">Hola, {user.username}</h1>
                <p className="text-32 mb-0">
                  <strong>No. {user.userId}</strong>
                </p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h2 className="h1">Este es el camino en tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <Step2Component user={user} />
              <div className="module-bottom40 row"></div>
              {(user.assignedTo || []).map(({ adminId, greeting }, index) => (
                <SimpleChatboxComponent
                  key={index}
                  chatUrl={`/chat-acompanante/${(adminId as IAdmin)._id}`}
                  companionName={(adminId as IAdmin).username}
                  companionRole={(adminId as IAdmin).principalRol}
                  greeting={
                    greeting ||
                    `Te damos la bienvenida a Ola. Te presentamos a tu ${
                      (adminId as IAdmin).principalRol
                    }`
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default LlamadaScreen;
