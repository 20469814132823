import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';
import cards from '../../../utils/cardsGestora.json';
import { IUser, UserSteps } from '../../../types/userInterface';
import SimpleChatboxComponent from '../../../components/Process/Chatbox/simpleChatbox.component';
import { IAdmin } from '../../../types/adminInterface';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import LoaderComponent from '../../../components/Loader/loader.component';
import { Link } from 'react-router-dom';

interface ChatGestoraScreenProps {
  user: Partial<IUser>;
  token: string;
}

const GestoraScreen: React.FC<ChatGestoraScreenProps> = () => {
  const [user, setUser] = useState<Partial<IUser>>({});
  const [loading, setLoading] = useState(true);

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Llamada telefónica',
      description: 'Listo',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { olaUser } = currentUser;
          setUser(olaUser);
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="container module-top">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="text-64 mb-3">Hola, {user.username}</h1>
                <p className="text-32 mb-0">
                  <strong>No. {user.userId}</strong>
                </p>
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12">
                <h2 className="h1">Este es el camino en tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <div className="col-12">
                <h3 className="h2 text-400">Accede a la ley 21.030</h3>
                <div className="alert alert--simple-check col-md-8 col-12">
                  <span className="icon material-icons-outlined">
                    check_circle
                  </span>
                  <span className="text-20">
                    <strong>
                      Recibimos tu selección de hospitales. Nos pondremos en
                      contacto para coordinar los detalles.
                    </strong>
                  </span>
                </div>
                <article>
                  <ul className="text-20">
                    <li className="mb-3">
                      Tendrás una llamada telefónica de presentación con tu
                      acompañante para guiar de mejor manera tu proceso. Ella
                      será tu guía personalizada durante el proceso.
                    </li>
                    <li className="mb-3">
                      Una vez que hayas atendido a tu hospital, te pediremos que
                      registres tu opinión sobre el servicio recibido.
                    </li>
                  </ul>
                </article>
              </div>
              <div className="col-lg-5 col-12">
                <div className="form-row module-top40 form--last">
                  <Link
                    to="/bitacora"
                    className="btn btn--type1"
                    onClick={(e) => {
                      if (user.step === UserSteps.GESTION) {
                        e.preventDefault();
                        Swal.fire({
                          title: 'Aún no es posible continuar',
                          text: 'Tan pronto se confirme tu procedimiento, podrás continuar.',
                          icon: 'info',
                          confirmButtonText: 'Aceptar',
                          cancelButtonText: 'De acuerdo',
                        });
                      }
                    }}
                  >
                    Déjanos tu opinión
                  </Link>
                </div>
                <div className="form-row form--last">
                  <button type="button" className="btn btn--type2">
                    Ya no necesito este acompañamiento
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12">
              {(user.assignedTo || []).map(({ adminId, greeting }, index) => (
                <SimpleChatboxComponent
                  key={index}
                  chatUrl={`/chat-acompanante/${(adminId as IAdmin)._id}`}
                  companionName={(adminId as IAdmin).username}
                  companionRole={(adminId as IAdmin).principalRol}
                  greeting={
                    greeting ||
                    `Te damos la bienvenida a Ola. Te presentamos a tu ${
                      (adminId as IAdmin).principalRol
                    }`
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <CardWrapperComponent
        color="base"
        title="¿Qué hace una gestora?"
        cards={cards}
      />
      <FooterShared />
    </>
  );
};

export default GestoraScreen;
