import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown';
import Swal from 'sweetalert2';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import LoaderComponent from '../../../components/Loader/loader.component';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { IQuestion, strapiDoc } from '../../../types/strapiDocs';
import { IUser, IUserBitacora, UserSteps } from '../../../types/userInterface';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import { fetchStrapiSingle } from '../../../api/strapi';
import { updateUser } from '../../../api/usersApi';

interface Props {
  // Define your component props here
}

const EvaluacionHospitalLey21Screen: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState<string>('');
  const [content, setContent] = useState<Partial<strapiDoc>>({});
  const [formularioValido, setFormularioValido] = useState(false);

  const navigate = useNavigate();

  const steps = [
    {
      title: 'Completa tu perfil',
      description: 'Registrada',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'En proceso',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Llamada telefónica',
      description: 'Pendiente',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Accede a la ley 21.030',
      description: 'Pendiente',
      status: 'status--success',
      completed: true,
      color: 'green350',
    },
    {
      title: 'Seguimos en contacto',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'pink400',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchSection = async () => {
    try {
      setLoading(true);
      const response = await fetchStrapiSingle('/evaluacion-hospital-no-ley', {
        populate: '*',
      });
      if (response.data) {
        setContent(response.data);
      }
    } catch (error) {
      console.error('Error al obtener la sección:', error);
      setError('Error al obtener la sección');
    } finally {
      setLoading(false);
    }
  };

  const setUserQuestion = (question: IQuestion, value: string) => {
    const userQuestions = user.bitacora || [];
    const existingQuestion = userQuestions.find((q) => q.id === question.id);
    if (existingQuestion) {
      existingQuestion.id = question.id;
      existingQuestion.answer = value;
      existingQuestion.question = {
        documentId: `${question.id}`,
        value: question.name || '',
      };
    } else {
      userQuestions.push({
        id: question.id,
        answer: value,
        question: {
          documentId: `${question.id}`,
          value: question.name || '',
        },
      });
    }

    console.log('setUserQuestion', { userQuestions });
    setUser({ ...user, bitacora: userQuestions });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userId = user._id || '';
    const newBitacora: IUserBitacora[] = [
      {
        id: 0,
        question: {
          documentId: '0',
          value:
            '¿Pudiste acceder un aborto a través de la implementación 21.030?',
        },
        answer: 'Si fuí al hospital pero no accedí a la ley 21.030',
      },
      ...(user.bitacora || []),
    ];
    const userUpdate: Partial<IUser> = {
      bitacora: newBitacora,
      step: UserSteps.EVALUACION_NO_LEY,
    };
    try {
      setLoading(true);
      await updateUser(userId, userUpdate, token);
      setUser(userUpdate);
      navigate('/retroalimentacion');
    } catch (error) {
      setError('Error al actualizar la información');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { olaUser, jwtToken } = currentUser;
          setUser(olaUser);
          setToken(jwtToken);
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    fetchSection();
  }, []);

  useEffect(() => {
    const bitacora = user.bitacora || [];
    setFormularioValido(bitacora.length > 0);
  }, [user]);

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <div className="col-12 mb-4">
                <a
                  href="#"
                  className="btn--icon btn--simple btn--simple--small"
                  onClick={() => navigate(-1)}
                >
                  <i className="icon fa-solid fa-arrow-left"></i> Regresar
                </a>
              </div>
              <div className="row">
                <div className="col-md-5 col-12">
                  <h2 className="text-green900 text-40 mb-3">
                    {content.attributes?.header || ''}
                  </h2>
                  <article className="text-20">
                    <p className="text-green350">
                      <strong>{content.attributes?.subheader || ''}</strong>
                    </p>
                    <Markdown>{content.attributes?.content}</Markdown>
                  </article>
                  <div className="form-block">
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        {content.attributes?.questions?.map(
                          (question, index) => {
                            const { id, name, tooltip } = question;
                            return (
                              <div
                                className="form-block row-check-2 mb-4"
                                key={index}
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input requiredField"
                                    type="checkbox"
                                    name={`question-${id}`}
                                    id={`question-${id}`}
                                    value={name || ''}
                                    onChange={(e) =>
                                      setUserQuestion(
                                        {
                                          id,
                                          name: 'Si fuí al hospital pero no accedí a la ley 21.030 porque',
                                          Nombre:
                                            'Si fuí al hospital pero no accedí a la ley 21.030 porque',
                                          type: 'checkbox',
                                        },
                                        e.target.value
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label label--icon"
                                    htmlFor={`question-${id}`}
                                  >
                                    {name || ''}
                                    {tooltip && tooltip !== '' && (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip>{tooltip}</Tooltip>}
                                      >
                                        <span className="material-icons-outlined icon">
                                          help_outline
                                        </span>
                                      </OverlayTrigger>
                                    )}
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        )}
                        <label className="form-label label--icon">
                          Ingresa una razón adicional
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>Ingresa una razón adicional</Tooltip>
                            }
                          >
                            <span className="material-icons-outlined icon">
                              help_outline
                            </span>
                          </OverlayTrigger>
                        </label>
                        <textarea
                          className="form-control"
                          id="otherUrgencia"
                          rows={3}
                          placeholder="¿Alguna que quieras agregar?"
                          onChange={(e) => {
                            const value = e.target.value;
                            setUserQuestion(
                              {
                                id: 99,
                                Nombre: 'Ingresa una razón adicional ',
                                name: 'Ingresa una razón adicional ',
                                type: 'text',
                              },
                              value
                            );
                          }}
                        />
                      </div>
                      <div className="form-row  form--last">
                        <button
                          type="submit"
                          className="btn btn--type1"
                          disabled={!formularioValido}
                        >
                          Siguiente
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <aside className="hide-tablet col-lg-7 col-12 d-flex justify-content-end">
                  <figure className="image-350">
                    <img
                      src="/assets/images/illustration/ilustration1.png"
                      alt="Te acompañamos"
                    />
                  </figure>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default EvaluacionHospitalLey21Screen;
