import React, { useEffect, useState } from 'react';
import { Button, Input, MessageBox, MessageBoxType } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { io, Socket } from 'socket.io-client';
import { IUser } from '../../types/userInterface';

interface ChatComponentProps {
  user: Partial<IUser>;
  roomId: string;
  token: string;
  messages: MessageBoxType[];
  setNewMessage?: (msg: MessageBoxType) => void;
}

interface roomMessage {
  room?: string;
  msg: string;
  sender: string;
  transactionId: number;
  senderName: string;
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  user,
  roomId,
  messages,
  setNewMessage,
}) => {
  const [socket, setSocket] = useState<Socket>();
  const [inputValue, setInputValue] = useState('');

  const handleSendMessage = () => {
    if (inputValue.trim() !== '') {
      console.log('Room ID:', user);
      const randomTransactionId = Math.floor(Math.random() * 1000000);
      if (socket) {
        socket.emit('roomMessage', {
          room: roomId,
          msg: inputValue,
          sender: user._id,
          transactionId: randomTransactionId,
          senderName: user.username || 'Anónimo',
        });
        pushNewMessage(
          inputValue,
          'right',
          randomTransactionId,
          user._id || '',
          user.username || 'Anónimo'
        );
        setInputValue('');
      }
    }
  };

  const pushNewMessage = (
    msg: string,
    position: string,
    transactionId: number,
    sender: string,
    senderName: string
  ) => {
    console.log('Received message:', {
      msg,
      messages: messages.length,
      transactionId,
    });
    const newMessage: MessageBoxType = {
      id: transactionId,
      position,
      type: 'text',
      text: msg,
      date: new Date(),
      avatar:
        sender === user._id
          ? '/assets/images/icons/user-pic-2.svg'
          : '/assets/images/icons/user-pic-2.svg',
      title: senderName,
      focus: false,
      forwarded: false,
      replyButton: false,
      removeButton: false,
      titleColor: '#000000',
      status: 'sent',
      notch: true,
      retracted: false,
    };
    // add to the beginning of the array
    setNewMessage ? setNewMessage(newMessage) : null;
    setInputValue('');
  };

  const noRepeatedMessages = (messages: MessageBoxType[]) => {
    const uniqueMessages = messages.filter(
      (msg, index, self) => index === self.findIndex((m) => m.id === msg.id)
    );
    return uniqueMessages;
  };

  useEffect(() => {
    const socket = io('https://olachat.manifesto.design/');
    setSocket(socket);
    socket.emit('joinRoom', roomId);

    socket.on('roomMessage', (data: roomMessage) => {
      console.log('Received roomMessage message:', data);
      if (data.sender !== user._id) {
        pushNewMessage(
          data.msg,
          'left',
          data.transactionId,
          data.sender,
          data.senderName
        );
      }
    });
  }, [user]);

  return (
    <section className="chatbox-placeholder ">
      <div className="chat-container">
        <div className="rce-container-mlist message-list">
          <div className="rce-mlist">
            {noRepeatedMessages(messages).map((msg) => (
              <MessageBox
                key={msg.id}
                id={msg.id.toString()}
                position={msg.position}
                type={msg.type as 'text'} // Casting para asegurar que el tipo es 'text'
                text={msg.text}
                date={msg.date}
                avatar={msg.avatar}
                title={msg.title}
                focus={msg.focus}
                titleColor={msg.titleColor}
                forwarded={msg.forwarded}
                replyButton={msg.replyButton}
                removeButton={msg.removeButton}
                status={msg.status}
                notch={msg.notch}
                retracted={msg.retracted}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="chatbox__send">
        <Input
          placeholder="Escribe tu mensaje..."
          multiline={false}
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
          maxHeight={100}
          rightButtons={
            <Button
              color="white"
              backgroundColor="#845DB6"
              text="Enviar"
              onClick={handleSendMessage}
            />
          }
        />
      </div>
    </section>
  );
};

export default ChatComponent;
