import React, { useState } from 'react';
import StopProcessComponent from '../../Modals/StopProcessModal/stopProcessModal.component';
import StopConfirmComponent from '../../Modals/StopConfirmModal/stopConfirmModal.component';
import { IUser, UserSteps } from '../../../types/userInterface';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

interface Props {
  user: Partial<IUser>;
}

const Step2Component: React.FC<Props> = ({ user }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShow, setModalShow] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };
  return (
    <>
      <StopProcessComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <StopConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="col-12">
        <h3 className="h2 text-400">Gestionar tu procedimiento</h3>
        <div className="alert alert--simple-check col-md-8 col-12">
          <span className="icon material-icons-outlined">check_circle</span>
          <span className="text-20">
            <strong>
              Recibimos tu información completa. Estamos asignándote a tu
              acompañante. Te mantendremos informada.
            </strong>
          </span>
        </div>
        <article>
          <ul className="text-20">
            <li className="mb-3">
              Tendrás una llamada telefónica de presentación con tu acompañante
              para guiar de mejor manera tu proceso. Ella será tu guía
              personalizada durante el proceso.
            </li>
            <li className="mb-3">
              Luego de ello, pueden acordar la mejor forma de comunicación entre
              ambas: chat, audios y/o llamadas.
            </li>
          </ul>
        </article>
      </div>
      <div className="col-lg-5 col-12">
        <div className="form-row module-top40 form--last">
          <Link
            to="/gestion"
            className="btn btn--type1"
            onClick={(e) => {
              if (user.step === UserSteps.LLAMADA) {
                e.preventDefault();
                Swal.fire({
                  title: 'Aún no es posible continuar',
                  text: 'Tan pronto se confirme tu llamada, podrás continuar con la gestión de tu procedimiento.',
                  icon: 'info',
                  confirmButtonText: 'Aceptar',
                  cancelButtonText: 'De acuerdo',
                });
              }
            }}
          >
            Gestiona tu procedimiento
          </Link>
        </div>
        <div className="form-row form--last">
          <button
            type="button"
            className="btn btn--type2"
            onClick={() => setModalShow(true)}
          >
            Ya no necesito este acompañamiento
          </button>
        </div>
      </div>
    </>
  );
};

export default Step2Component;
