import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

declare global {
  interface Window {
    google: unknown;
  }
}

interface Props {
  id?: number;
  name?: string;
  service?: string;
  address?: string;
  phone?: string[] | undefined;
  review?: string;
  lat?: number;
  long?: number;
}

const HospitalCardMap: React.FC<Props> = ({
  id,
  name,
  service,
  address,
  phone,
  review,
  lat,
  long,
}) => {
  const mapStyles = {
    height: '265px',
    width: '100%',
  };

  if (lat === undefined || long === undefined) {
    return null; // o puedes renderizar algún componente de fallback aquí
  }

  const defaultCenter = {
    lat: lat,
    lng: long,
  };

  console.log(defaultCenter);

  return (
    <div className="col-lg-4 col-12">
      <div
        className="hospital-card mb-4"
        data-lat={lat}
        data-long={long}
        data-id={id}
      >
        <div className="row">
          <div className="col-12">
            <div className="map-small">
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={16}
                center={{ lat: lat, lng: long }}
              >
                <Marker key={id} position={defaultCenter} />
              </GoogleMap>
            </div>
          </div>
          <div className="col-12 mb-4">
            {name ? <h3 className="text-wine400 text-16">{name}</h3> : null}
            {service ? (
              <p>
                <strong>Servicio de Salud:</strong> {service}
              </p>
            ) : null}
            {review === 'bad' && (
              <p className="tag tag--error ">
                <span className="material-icons-outlined">close</span> Malas
                experiencias
              </p>
            )}
            {review === 'medium' && (
              <p className="tag tag--warning">
                <span className="material-icons-outlined">warning</span>
                Experiencias mixtas
              </p>
            )}
            {review === 'good' && (
              <p className="tag tag--success">
                <span className="material-icons-outlined">check</span> Buenas
                experiencias
              </p>
            )}
            {address ? (
              <p className="text-gray600 text-icon hospital-card__text">
                <span className="material-icons-outlined">place</span> {address}
              </p>
            ) : null}
            {phone ? (
              <p className="text-icon hospital-card__text">
                <span className="material-icons-outlined">call</span>{' '}
                {phone.map((phoneNumber, index) => {
                  if (index === phone.length - 1) {
                    return phoneNumber;
                  } else if (index === phone.length - 2) {
                    return phoneNumber + ' y ';
                  } else {
                    return phoneNumber + ', ';
                  }
                })}
              </p>
            ) : null}
          </div>
          <aside className="col-12">
            <a
              href="/gestion"
              type="button"
              className={`btn btn--type4 btn--100`}
            >
              Cambiar hospital
            </a>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default HospitalCardMap;
